<script>
// import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover, debounce, date} from "uloc-vue"
import {list, updateCliente} from "@/domain/gerencia/clientes/services"
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating"
import {datePtToEn} from "@/utils/date"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import clienteWindow from "components/clientes/components/cliente/window/cliente"
import StatusCliente from "components/gerencia/cliente/components/include/StatusCliente"
export default {
  name: 'ClientesAnotador',
  inject: ['container'],
  mixins: [],
  components: {
    StatusCliente,
    SatisfacaoRating,
    UPopover,
    MenuOptionsItem
  },
  data () {
    return {
      routeName: ['clientes.lista'],
      table: {
        serverData: [],
        filters: {
          id: null,
          busca: null,
          representante: null,
          status: ['active', 'maintenance', 'suspended', 'homolog'],
          active: null
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 1000
        }
      }
    }
  },
  watch: {
  },
  mounted() {
    this.load()
  },
  computed: {
  },
  methods: {
    load () {
      this.table.loading = true
      const pagination = {
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      }
      let extraFilters = []

      this.table.filters.busca && extraFilters.push(`&search=${String(this.table.filters.busca).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.gerente && extraFilters.push(`&gerente=${this.table.filters.gerente.id}`)
      this.table.filters.status.length && extraFilters.push(`&status=${this.table.filters.status.join(',')}`)

      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dominio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)
      list(1000, 1, filtros, false)
          .then(({data}) => {
            // this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    changeObs: debounce(function (event, cliente) {
      console.log('Change OBS', cliente)
      const element = document.getElementById('status-client-' + cliente.id)
      element.classList.add('text-positive')
      element.innerHTML = 'Atualizando...'
      updateCliente(cliente.id, {
        observacao: cliente.observacao
      }, 'PATCH')
          .then(response => {
            element.innerHTML = ''
          })
          .catch(error => {
            this.alertApiError(error)
            element.innerHTML = 'Falha'
            element.classList.remove('text-positive')
            element.classList.add('text-negative')
          })
    }, 1000),
    abrir (cliente) {
      let routeData = this.$router.resolve({ name: 'clientes.cliente', params: {id: cliente.id} })
      window.open(routeData.href, '_blank')
    },
    clienteWindow,
    editar (cliente) {
      this.clienteWindow(cliente.id)
    },
  },
  meta: {
    title: 'Clientes - Anotador',
    name: 'ClientesAnotador'
  }
}
</script>

<template>
  <div class="loading" v-if="table.loading">
    Aguarde enquanto montamos o anotador...
  </div>
  <div v-else>
    <div class="console-cliente-anotador">
      <div class="cc box-v2" :class="['cc-class-' + cliente.indiceSatisfacao]" v-for="cliente in table.serverData" :key="'cliente-' + cliente.id">
        <div class="cc-header">
          <div>
            <div class="font-bold"><a :href="'https://' + cliente.dominio" target="_blank" class="text-black" style="font-weight: bold">{{cliente.dominio}}</a></div>
            <span v-if="cliente.pessoa">{{cliente.pessoa.name}}</span>
          </div>
          <div class="plan">
            <div>
              <table>
                <tr>
                  <td style="width: 20px">P</td>
                  <td>{{cliente.plano.nome}}</td>
                </tr>
              </table>
            </div>
            <div>
              <table>
                <tr>
                  <td style="width: 20px">M</td>
                  <td>{{cliente.mensalidade|moeda}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="cc-menu">
            <a @click="abrir(cliente)"><i class="fa-duotone fa-eye" /></a>
            <a @click="editar(cliente)" class="m-l-sm"><i class="fa-duotone fa-edit" /></a>
            <u-btn flat dense size="sm" class="m-l-xs btn--no-hover hide">
              <i class="fa fa-ellipsis-h font-14" />
              <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 0]" style="min-width: 172px">
                <div>
                  <ul>
                    <!--<menu-options-item @click.native="abrir(cliente)" label="Acessar Cliente" close  />
                    <menu-options-item @click.native="editar(cliente)" label="Editar Cliente" close  />-->
                    <menu-options-item icon="file-contract" icon-type="fa" label="Contrato" close  />
                  </ul>
                </div>
              </u-popover>
            </u-btn>
          </div>
        </div>
        <div class="cc-note">
          <textarea class="note-input sl-textarea" v-model="cliente.observacao" @keyup="event => changeObs(event, cliente)" rows="10"></textarea>
          <p :id="'status-client-' + cliente.id" class="text-positive"></p>
        </div>
        <div class="plan-stats flex flex-center font-11">
          <div>
            DPV: {{cliente.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy')}}
            <u-tooltip :offset="[10,10]" anchor="bottom middle" self="top middle">
              Dia do próximo faturamento
            </u-tooltip>
          </div>
          <div>
            DUP: {{cliente.ultimoPagamento|formatDate('dd/MM/yyyy')}}
            <u-tooltip :offset="[10,10]" anchor="bottom middle" self="top middle">
              Dia do último pagamento
            </u-tooltip>
          </div>
          <div>
            TKI: {{cliente.ticketIn|moeda}}
            <u-tooltip :offset="[10,10]" anchor="bottom middle" self="top middle">
              Valor total recebido do cliente
            </u-tooltip>
          </div>
          <div>
            TKO: {{cliente.ticketOut|moeda}}
            <u-tooltip :offset="[10,10]" anchor="bottom middle" self="top middle">
              Valor total gasto pelo cliente
            </u-tooltip>
          </div>
          <div class=" text-uppercase">
            <div :class="'cliente-status-' + cliente.status" class="flex flex-center">
              <span class="m-r-xs">STA:</span><status-cliente :cliente="cliente" />
            </div>
            <u-tooltip :offset="[10,10]" anchor="bottom middle" self="top middle">
              Status do cliente
            </u-tooltip>
          </div>
        </div>
        <div class="flex flex-center m-t-sm">
          <satisfacao-rating :cliente="cliente" size="sm" />
        </div>
      </div>
    </div>
  </div>
</template>
